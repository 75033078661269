import React from "react"
import { usePages } from "../hooks/usePages"
import ContactView from "../views/ContactView"

const Contact = () => {
  const info = usePages("CONTACT")

  return <ContactView info={info} />
}

export default Contact
